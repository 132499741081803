//Font Awesome
import '../bundle/font-awesome/scss/fontawesome.scss'
import '../bundle/font-awesome/scss/solid.scss'
import '../bundle/font-awesome/scss/brands.scss'
import '../bundle/font-awesome/scss/light.scss'
import '../bundle/font-awesome/scss/regular.scss'
import '../bundle/font-awesome/scss/duotone.scss'

import '../bundle/scss/_variables.scss'
import '../bundle/front-ui/vendor/hs-mega-menu/src/scss/hs-mega-menu.scss'
import '../bundle/front-ui/vendor/slick-carousel/slick/slick.css'
import '../bundle/front-ui/vendor/select2/src/scss/core.scss'
import '../bundle/scss/aos.scss'
import '../bundle/front-ui/scss/theme.scss'

//Bootstrap UI
import '../bundle/scss/bootstrap-addons.scss'
import '../bundle/scss/styles-global-UI.scss'

//Media Query Bootstrap
import '../bundle/scss/sm-breakpoints.scss'
import '../bundle/scss/md-breakpoints.scss'
import '../bundle/scss/lg-breakpoints.scss'
import '../bundle/scss/xl-breakpoints.scss'

///////////////Javascript//////////////////
///////////////////////////////////////////

//jQuery, Bootstrap, Popper
import 'jquery'
import '../bundle/js/jquery-migrate'
import 'bootstrap'

//Component Vendors
import '../bundle/front-ui/vendor/appear'
import '../bundle/js/stat-circles'

//Script inits
import '../bundle/js/pre-loader'
import '../bundle/js/front-init'


